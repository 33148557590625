<template>
  <div class="info-container">
    <!-- <NavBack :navBackMsg="navBackMsg"></NavBack> -->
    <!-- 覆盖最上方静止选择和修改数据 -->
    <div class="disabled-box" v-if="!saveShow"></div>
    <div class="info-cen">
      <div class="title">
        <div class="title-l">
          <svg class="closeImg" aria-hidden="true">
            <use :xlink:href="'#icon-morenerji'" />
          </svg>
          {{ navBackMsg.title }}
        </div>
        <div class="title-r" v-if="menuLayout !== '1'">
          <van-button icon="add-o" round type="info" @click="addItem"
            >添加</van-button
          >
        </div>
      </div>
      <div
        class="info-main"
        v-for="(parent, index) in fromData"
        :key="index"
        style="margin-bottom: 15px"
      >
        <van-cell-group>
          <van-cell v-for="(item, index2) in parent" :key="index2">
            <!-- <OcrIdentityView
              v-if="item.type === 'uploadImg'"
              :value.sync="item.value"
            >
            </OcrIdentityView> -->
            <van-field
              v-if="item.type === 'text'"
              v-model="item.value"
              :label="item.label"
              :required="item.required"
              right-icon="custom"
              placeholder="请填写"
              input-align="right"
              :maxlength="item.propertyLength"
            >
              <van-icon
                class="iconfont icon-kong"
                class-prefix="icon"
                slot="right-icon"
                name="custom"
              ></van-icon>
            </van-field>
            <van-field
              v-if="item.type === 'input2'"
              v-model="item.value"
              :label="item.label"
              :required="item.required"
              right-icon="custom"
              placeholder="请填写"
              input-align="right"
            >
              <van-icon
                class="iconfont"
                :class="
                  cardCode ? 'icon-zizhuruzhi-miwen' : 'icon-zizhuruzhi-mingwen'
                "
                class-prefix="icon"
                slot="right-icon"
                name="custom"
                @click="iconClick(item)"
              ></van-icon>
            </van-field>
            <van-field
              v-if="item.type === 'textArea'"
              v-model="item.value"
              :label="item.label"
              rows="1"
              type="textarea"
              :required="item.required"
              :autosize="item.autosize"
              right-icon="custom"
              placeholder="请填写"
              input-align="right"
            >
              <van-icon
                class="iconfont icon-kong"
                class-prefix="icon"
                slot="right-icon"
                name="custom"
              ></van-icon>
            </van-field>
            <SelectView
              v-if="item.type === 'select'"
              :value.sync="item.value"
              :list="item.list"
              :label="item.label"
              :required="item.required"
            ></SelectView>
            <DatetimeView
              v-if="item.type === 'date'"
              :value.sync="item.value"
              :type="item.type"
              :label="item.label"
              :required="item.required"
            ></DatetimeView>
            <AreaView
              v-if="item.type === 'cascader'"
              :value.sync="item.value"
              :label="item.label"
              :required="item.required"
            ></AreaView>
            <SwitchView
              v-if="item.type === 'boolean'"
              :value.sync="item.value"
              :label="item.label"
              :required="item.required"
              @switchValChange="switchValChange(item)"
            ></SwitchView>
            <UploadImgView
              v-if="item.type === 'uploadImg'"
              :label="item.label"
              :list.sync="item.list"
              :disabled="!saveShow"
            >
            </UploadImgView>
          </van-cell>
          <van-cell v-if="index !== 0">
            <p class="del-btn" @click="delItem(index)">删除</p>
          </van-cell>
        </van-cell-group>
      </div>
    </div>
    <div class="footer">
      <van-button type="info" @click="save" v-btnClick v-if="saveShow"
        >保存</van-button
      >
    </div>
  </div>
</template>
<script>
import SelectView from "../../components/select-view/index"; //下拉选择
import DatetimeView from "../../components/Datetime-view/index"; //日期类型选择
import OcrIdentityView from "../../components/ocr/ocr-identity-view"; //身份证OCR
import AreaView from "../../components/Area-view/index";
import NavBack from "../../components/nav-back/index";
import SwitchView from "../../components/switch-view/index"; //滑块
import UploadImgView from "../../components/upload-img-view/index"; //上传证书
import utils from "@/utils/index";
import { mapGetters } from "vuex";
import API from "../../api/http";
import { Toast } from "vant";
import { Dialog } from "vant";
/**
  type 类型：
     input：普通input框,右边无icon
    input2: 右边icon有点击事件的input框
    select：下拉
    date：日期
    area：省市区
    textarea：多列展示
    identity：身份证
 */
export default {
  components: {
    SelectView,
    DatetimeView,
    AreaView,
    OcrIdentityView,
    NavBack,
    SwitchView,
    UploadImgView,
  },
  data() {
    return {
      navBackMsg: {
        title: "", // 导航文字内容
        backBtn: true,
      },
      fromData: [
        // {
        //   list:[
        //       {
        //       label: "姓名",
        //       required: true,
        //       value: "",
        //       type: "input",
        //     },
        //     {
        //       label: "与员工关系",
        //       required: true,
        //       value: "",
        //       type: "select",
        //       showPopup: false,
        //       list: [
        //               {
        //                 value: 1,
        //                 text: "母亲",
        //               },
        //               {
        //                 value: 2,
        //                 text: "父亲",
        //               },
        //               {
        //                 value: 3,
        //                 text: "配偶",
        //               },
        //             ],
        //           },
        //           {
        //             label: "联系电话",
        //             required: true,
        //             value: "",
        //             type: "input",
        //           },
        //           {
        //             label: "邮箱",
        //             required: false,
        //             value: "",
        //             type: "input",
        //           },
        //           {
        //             label: "省、市、区",
        //             required: true,
        //             value: {
        //               companyProvince: "140000",
        //               companyCity: "140100",
        //               companyDistrict: "140105",
        //             },
        //             type: "area",
        //           },
        //           {
        //             label: "详细地址",
        //             required: true,
        //             value: "",
        //             autosize: true,
        //             type: "textarea",
        //           },
        //           {
        //             label: "设为紧急联系人",
        //             required: false,
        //             value: false,
        //             type: "switch",
        //           },
        //     ]
        // },
      ],
      cardCode: false,
      approvalStatus: "",
      menuLayout: "1",
    };
  },
  created() {
    this.templateMenuId = this.$route.query.id;
    this.navBackMsg.title = this.$route.query.title;
    this.approvalStatus = Number(this.$route.query.astatus);
    this.menuLayout = this.$route.query.menuLayout;
  },
  mounted() {
    this.getInit();
  },
  computed: {
    ...mapGetters("user", {
      clientCode: "clientCode",
      temporaryCode: "temporaryCode",
      mcode: "mcode",
    }),
    saveShow() {
      if (this.mcode) {
        //人员状态未10、20,可以再次保存
        if (this.approvalStatus === 10 || this.approvalStatus === 20) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
  methods: {
    getInit() {
      API.getFieldByMenu({
        templateMenuId: this.templateMenuId,
        clientCode: this.clientCode,
        temporaryCode: this.temporaryCode,
        mcode: this.mcode,
      }).then((res) => {
        let result = res.data;
        this.fromData = this.setList(result);
        // // 循环给出默认值
        this.fromData.map((item) => {
          item.map((item2) => {
            // 默认户籍地址
            // alert(item2.value)
            if (
              item2.fieldCode === "contacts_address_type" &&
              item2.value === "户籍地址"
            ) {
              item2.value = "0";
            }
          });
        });
        // this.fromData =
        // this.fromData =
      });
    },
    addItem() {
      //添加
      if (
        this.menuLayout !== "N" &&
        Number(this.menuLayout) === this.fromData.length
      ) {
        Toast({
          message: `最多添加${this.menuLayout}个${this.navBackMsg.title}`,
          className: "totast-cum",
        });
        return;
      }
      let arr = JSON.parse(JSON.stringify(this.fromData[0]));
      arr.forEach((item) => {
        if (item.type === "boolean") {
          item.value = false;
        } else if (item.type === "uploadImg") {
          item.list = [];
        } else {
          item.value = "";
        }
      });
      this.fromData.push(arr);
    },
    setFromData() {
      //将省份证号码转为 密文显示
      this.fromData.map((item) => {
        if (item.type === "input2") {
          this.cardCodeValue = JSON.parse(JSON.stringify(item.value));
          item.value = utils.setIdCard(item.value);
        }
      });
    },
    iconClick(item) {
      //身份证  铭文/密文切换
      this.cardCode = !this.cardCode;
      item.value = this.cardCode
        ? this.cardCodeValue
        : utils.setIdCard(item.value);
    },
    setList(arr) {
      //数据重组
      let data = arr.map((item) => {
        item = item.map((child) => {
          let value = child.fieldValue;
          if (child.propertyType === "boolean") {
            value = JSON.parse(value);
          }
          if (child.propertyType === "cascader") {
            //拼接地址为固定格式（省、市、区）
            // {companyCity: "110000", companyDistrict: "110100", companyProvince: "110101"}
            let obj = JSON.parse(value || "[]");
            value =
              obj.length === 0
                ? ""
                : {
                    companyProvince: obj[0],
                    companyCity: obj[1],
                    companyDistrict: obj[2],
                  };
          }
          return {
            label: child.fieldDesc,
            value: value,
            type: child.propertyType,
            fieldCode: child.fieldCode,
            tipsDesc: child.tipsDesc,
            required: child.isNotNull === 1 ? true : false,
            propertyLength:
              child.propertyLength &&
              child.propertyLength !== 0 &&
              child.propertyLength !== ""
                ? child.propertyLength
                : 200,
            clientTemplateCheckField: child.clientTemplateCheckField,
            list: child.downList.map((dom) => {
              return {
                value:
                  child.fieldDesc === "国际区域码"
                    ? dom.dataValue
                    : dom.dataCode,
                // text: "+" + dom.dataCode,
                text:
                  child.fieldDesc === "国际区域码"
                    ? "+" + dom.dataValue
                    : dom.dataValue,
              };
            }),
          };
        });

        return item;
      });

      return data;
    },
    save() {
      let flag = true;

      // 判断地址类型重复
      let itemList = this.fromData.map((ele) => {
        return ele[0];
      });

      if (itemList.length > 1) {
        let ticketNum = itemList[0].value; // //取出第一个对象的属性值
        let isFlag = itemList.every((ele) => {
          return ele.value === ticketNum;
        });

        if (isFlag) {
          Toast({
            message: "地址类型重复",
          });
          return;
        }
      }

      for (let parentsItem of this.fromData) {
        for (let item of parentsItem) {
          if (item.required && item.value === "") {
            let tipsDesc = "";
            //没有提示信息的时候
            if (item.tipsDesc === "") {
              if (item.type === "select" || item.type === "cascader") {
                tipsDesc = "请选择";
              } else if (item.type === "text") {
                tipsDesc = "请填写";
              }
            } else {
              tipsDesc = item.tipsDesc;
            }

            Toast({
              message: tipsDesc + item.label,
              // className: "totast-cum",
            });
            flag = false;
            break;
          } else if (
            item.value !== "" &&
            item.clientTemplateCheckField !== null
          ) {
            //校验输入的值正则表达式
            let reg = eval(item.clientTemplateCheckField.checkFieldRegexp);
            if (!reg.test(item.value)) {
              Toast({
                message: `${item.tipsDesc}正确的${item.label}`,
              });
              flag = false;
              break;
            }
          }
        }
      }
      if (flag) {
        //成功
        let list = this.setFroms();
        API.addBasicFieldVale({
          templateMenuId: this.templateMenuId,
          temporaryCode: this.temporaryCode,
          menuFieldRelList: list,
          clientCode: this.clientCode,
          mcode: this.mcode,
        }).then((res) => {
          Toast({
            message: res.message,
            className: "toast-yellow",
          });
          this.saveBack();
        });
      }
    },
    saveBack() {
      setTimeout(() => {
        this.$router.back(-1);
        // this.$router.push({ path: "/user", query: { ...this.$route.query } });
      }, 1000);
    },
    setFroms() {
      let menuFieldRelList = this.fromData.map((item) => {
        item = item.map((element) => {
          let value = element.value;
          if (element.type === "select") {
            value = String(element.value);
          } else if (element.type === "cascader") {
            // console.log(Object.values(value));
            value = JSON.stringify(Object.values(value));
          }
          return {
            fieldCode: element.fieldCode,
            fieldValue: value,
          };
        });
        return item;
      });

      return menuFieldRelList;
    },
    delItem(index) {
      //删除某一项
      Dialog.confirm({
        // title: '标题',
        message: "确认要删除吗？",
      })
        .then(() => {
          // on confirm
          this.fromData.splice(index, 1);
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
</style>
